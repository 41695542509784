import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useCmsApi } from "src/_common/hooks/actions/cms/cmsApiHook";
import { useToaster } from "src/_common/hooks/actions/common/appToasterHook";
import parse from "html-react-parser";
import Sponsor from "../common/Sponsor";
import MetaDecorator from "../../_common/hooks/custom/MetaDecorator";
import { PAGE_TITLE } from "../../_config";
export default function CmsPage() {
  const { slug, gameId } = useParams<any>();
  const cmsApi = useCmsApi();
  const toast = useToaster();

  const [content, setContent] = useState<any>();

  const getCmsContentDetails = () => {
    let params: any = {
      slug,
    };
    if (gameId) {
      params["id"] = gameId
    }
    cmsApi.callGetCmsContent(
      params,
      (message: string, resp: any) => {
        if (resp && resp.data) {
          setContent(resp.data);
        } else {
          setContent({});
        }
      },
      (message: string) => {
        toast.error(message)
      }
    );
  };

  useEffect(() => {
    if (slug) {
      getCmsContentDetails();
    }
  }, [slug]);

  return (
    <React.Fragment>
      <MetaDecorator title={PAGE_TITLE + content?.meta_title} description={content?.meta_description} keywords={content?.meta_keyword} />

      <div className="container main-container main-container-top-padd">
        <div className="specific_rider">
          {content?.banner?.id != 0 ? (
            <div className="specific_riderImg">
              <img src={content?.banner?.original} />
            </div>
          ) : null}
          <div className="specific_ridercont">
            <div className="row">
              <div className="col-md-12">
                <h3>{content?.title}</h3>
                <div className="normal-text cms-content">
                  {!content
                    ? "Loading......"
                    : content?.long_description
                      ? parse(content?.long_description)
                      : "Admin not added any content"}
                </div>
              </div>
            </div>
          </div>
          {content?.document && content?.document?.id != 0 ? (
            <div>
              <a target="_blank" href={content?.document?.original} className="red-btn text-uppercase mb-3">
                Download
              </a>

              <div className="iframe-container">
                <iframe frameBorder="0" src={content?.document?.original} width="100%" height="800"></iframe>
              </div>
            </div>
          ) : null}
        </div>

        <Sponsor />
      </div>
    </React.Fragment>
  );
}
