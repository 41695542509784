import React, { useEffect, useState } from 'react'
import $ from "jquery";
import { NavLink, useParams } from "react-router-dom";
import { useEventApi } from 'src/_common/hooks/actions/event/appEventApiHook';
import { useToaster } from "src/_common/hooks/actions/common/appToasterHook";
import { useTopHeaderScoreSelector } from 'src/_common/hooks/selectors/scoreSelector';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import moment from "moment";
function TopHeaderScore() {
    const properties = {
        duration: 3000,
        transitionDuration: 700,
        infinite: true,
        indicators: false,
        arrows: true,
        autoplay: false
    }
    const mobileproperties = {
        duration: 3000,
        transitionDuration: 700,
        infinite: false,
        indicators: false,
        arrows: true,
        autoplay: false,
        prevArrow: (
            <div className='precls'>
                &#129168;
            </div>
        ),
        nextArrow: (
            <div className='nextcls'>
                &#129169;
            </div>
        )
    }
    const hideplan = {
        display: 'none'
    };
    const showplan = {
        display: 'block'
    };
    const toast = useToaster();
    const eventApi = useEventApi()
    const topGameTabData = useTopHeaderScoreSelector()

    const [userAgent, setUserAgent] = useState<String>("D");
    const [mcurTab, setMcurTab] = useState<number>(0);
    const [curTab, setCurTab] = useState<number>(0);
    const [vtype, setVtype] = useState<number>(0);
    const [fsindex, setFindex] = useState<number>(0);
    const [ssindex, setSindex] = useState<number>(1);
    const [isLoad, setLoad] = useState<number>(0);
    useEffect(() => {
        getTopHeaderScoreSection()
    }, [])



    //Get Top Score List
    const getTopHeaderScoreSection = () => {
        if (navigator.userAgent.match(/(Android|iPhone)/i) && navigator.userAgent.match(/WebKit/i)) {
            setUserAgent("M")
        }
        else {
            setUserAgent("D")
        }
        eventApi.callGetTopHeaderScore({}, (message: string, resp: any) => {
            if (navigator.userAgent.match(/(Android|iPhone)/i) && navigator.userAgent.match(/WebKit/i)) {
                mobSelectTab(0)
            } else {
                selectTab(0)
            }
        }, (message: string) => {
            toast.error(message)
        })
    }

    const calculatePerformance = (type: any) => {
        let findex = fsindex
        let sindex = ssindex
        setVtype(type)
        let perf1Name = null
        let perf2Name = null
        if (topGameTabData?.games[type]?.items[findex]?.performance)
            perf1Name = topGameTabData.games[type].items[findex].performance.title
        if (topGameTabData?.games[type]?.items[sindex]?.performance)
            perf2Name = topGameTabData.games[type].items[sindex].performance.title

        $('.precls').html(perf1Name)
        if (perf2Name)
            $('.nextcls').html(perf2Name)
        else
            $('.nextcls').hide()
    }

    const chnagePerformance = (prevIndex: any, newIndex: any) => {
        if (newIndex > prevIndex) {
            prevIndex = prevIndex + 1
            newIndex = prevIndex + 1
        }
        else {
            prevIndex = prevIndex - 1
            newIndex = prevIndex + 1
        }
        let perf1Name = ''
        let perf2Name = ''
        let type = vtype
        if (topGameTabData && topGameTabData.games[type] && topGameTabData.games[type].items) {
            if (topGameTabData.games[type].items[prevIndex] && topGameTabData.games[type].items[prevIndex].performance)
                perf1Name = topGameTabData.games[type].items[prevIndex].performance.title
            else
                perf1Name = ""
            if (topGameTabData.games[type].items[newIndex] && topGameTabData.games[type].items[newIndex].performance)
                perf2Name = topGameTabData.games[type].items[newIndex].performance.title
            else {
                perf2Name = ""
            }
        }
        $('.precls').html(perf1Name)
        $('.nextcls').html(perf2Name)
    }

    const selectTab = (i: any) => {
        setLoad(0)
        $(".tab-menu").hide()
        $("#tab" + i).show()
        $("#myTabContent").removeClass('hidemenuloading')
        setCurTab(i)
        setTimeout(() => {
            setLoad(1)
        }, 500);
    }

    const mobSelectTab = (i: any) => {
        setLoad(0)
        $(".tab-menu-desk").hide()
        $("#mtab" + i).show()
        $('.nextcls').show()
        $("#myMTabContent").removeClass('hidemenuloading')
        setMcurTab(i)
        $('.owl-carousel').trigger('refresh.owl.carousel');

        if (navigator.userAgent.match(/(Android|iPhone)/i) && navigator.userAgent.match(/WebKit/i)) {
            setFindex(0)
            setSindex(1)
            calculatePerformance(i)
            setTimeout(() => {
                setLoad(1)
            }, 500);
        }
    }

    return (
        <React.Fragment>
            <div className="top-stats-panel">
                {userAgent == 'D' && <div className="container top-stats-panel-inner  top-stats-panel-desk">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        {topGameTabData?.games?.length > 0 ? topGameTabData.games.map((v: any, i: number) => {
                            return (<li className="nav-item" key={i}>
                                <button className={i == curTab ? "nav-link active" : "nav-link"} id={"tab" + i + "-tab"} data-bs-toggle="pill1" data-bs-target={"#tab" + i} type="button" role="tab" aria-controls={"tab" + i} aria-selected="false" onClick={(e) => selectTab(i)}>{v.title}</button>
                            </li>)
                        }) : null}
                    </ul>
                    <div className="tab-content hidemenuloading" id="myTabContent">
                        {topGameTabData?.games?.length > 0 ? topGameTabData.games.map((v: any, i: number) => {
                            return (<div className="tab-menu tab-pane fade show active" key={i} id={"tab" + i} role="tabpanel" aria-labelledby={"tab" + i + "-tab"}>
                                {v && v.items.length > 0 ?
                                    <Slide {...properties}>
                                        {v.items.map((p: any, j: number) => {
                                            return (<div className="top-stats-list-panel gap-3" key={j}>
                                                <div className='d-flex justify-content-between'>
                                                    <h4 className='quick-leaderboard-heading for-desktop'>Quick Roundwise Leaderboard (<NavLink exact to={'/game/' + v.slug}>See All</NavLink>)</h4>
                                                    <h5>{p.performance.title} {p.performance && p.performance.start_date_time ? moment(p.performance.start_date_time).format('MMM D') : null}
                                                        {p.performance && p.performance.end_date_time && p.performance.start_date_time && p.performance.start_date_time != p.performance.end_date_time ? "-" + moment(p.performance.end_date_time).format('MMM D') : null}</h5>
                                                </div>

                                                {p.top_result.length > 0 && isLoad ?
                                                    <OwlCarousel
                                                        className="owl-carousel topmenu-carousel owl-theme"
                                                        items={4}
                                                        loop={false}
                                                        margin={13}
                                                        nav={true}
                                                        autoplay={false}
                                                        autoplayTimeout={3000}
                                                        autoplayHoverPause={true}
                                                        responsiveClass={true}
                                                        responsive={
                                                            {
                                                                0: {
                                                                    items: 2,
                                                                    nav: true
                                                                },
                                                                600: {
                                                                    items: 4,
                                                                    nav: false
                                                                },
                                                                1000: {
                                                                    items: 5,
                                                                    nav: true,
                                                                    margin: 16
                                                                },
                                                                1100: {
                                                                    items: 6,
                                                                    nav: true,
                                                                    margin: 16
                                                                }
                                                            }
                                                        }
                                                    >{p.top_result.map((x: any, k: number) => {
                                                        return (<div className="top-stats-block" key={k}>

                                                            <div className="top-stats-icon top-stats-icon-cup">
                                                                {k != 0 ? <i className="rounded-no">{k + 1}</i> : <img src="/images/cup.svg" alt="" />}
                                                            </div>
                                                            <div className="top-stats-content">
                                                                <h4>{x.team_name}</h4>
                                                                <div className="d-flex gap-3">
                                                                    <p>${v.game_type == 2 || v.game_type == 3 ? parseFloat(x.all_winnings).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : parseFloat(x.winnings).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                                                    <p style={v.game_type == 2 || v.game_type == 3 || v.game_type == 4 ? hideplan : showplan}>${parseFloat(x.tie_winnings).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                                                </div>
                                                            </div>
                                                        </div>)
                                                    })}
                                                    </OwlCarousel> : <div className='text-center text-red'>Results not published yet!</div>}

                                            </div>)
                                        })}
                                    </Slide> : <div className='text-center text-red'>Results not published yet!</div>}
                            </div>)
                        }) : null}

                    </div>
                </div>}

                {userAgent == 'M' && <div className="container top-stats-panel-inner  top-stats-panel-mob">
                    <ul className="nav nav-tabs" id="myMTab" role="tablist">
                        {topGameTabData?.games?.length > 0 ? topGameTabData.games.map((v: any, i: number) => {
                            return (<li className="nav-item" key={i}>
                                <button className={i == mcurTab ? "nav-link active" : "nav-link"} id={"mtab" + i + "-tab"} data-bs-toggle="pill2" data-bs-target={"#mtab" + i} type="button" role="tab" aria-controls={"mtab" + i} aria-selected="false" onClick={(e) => mobSelectTab(i)}>{v.title}</button>
                            </li>)
                        }) : null}
                    </ul>
                    <div className="tab-content hidemenuloading" id="myMTabContent">
                        {topGameTabData?.games?.length > 0 ? topGameTabData.games.map((v: any, i: number) => {
                            return (<div className="tab-menu tab-menu-desk tab-pane fade show active" key={i} id={"mtab" + i} role="tabpanel" aria-labelledby={"mtab" + i + "-tab"}>
                                {v && v.items.length > 0 &&
                                    <Slide  {...mobileproperties} onChange={(oldIndex, newIndex) => {
                                        chnagePerformance(oldIndex, newIndex)
                                    }}>
                                        {v.items.map((p: any, j: number) => {
                                            return (<div className="top-stats-list-panel gap-3" key={j}>
                                                <div className="top-stats-block top-stats-block-mob">
                                                    <div className="top-stats-content">
                                                        <h4>Team</h4>
                                                        <div className="d-flex gap-4">
                                                            <p>Winnings</p>
                                                            <p>Tie-Breakers</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                {p.top_result.length > 0 ?
                                                    <OwlCarousel
                                                        className="owl-carousel topmenu-mob-carousel owl-theme"
                                                        items={3}
                                                        loop={false}
                                                        margin={16}
                                                        nav={false}
                                                        autoplay={false}
                                                        autoplayTimeout={3000}
                                                        autoplayHoverPause={true}
                                                        responsiveClass={true}
                                                        responsive={
                                                            {
                                                                0: {
                                                                    items: 2,
                                                                    nav: false
                                                                },
                                                                600: {
                                                                    items: 2,
                                                                    nav: false
                                                                },
                                                                1000: {
                                                                    items: 3,
                                                                    nav: false,
                                                                    margin: 16
                                                                }
                                                            }
                                                        }
                                                    >{p.top_result.map((x: any, k: number) => {
                                                        return (<div className="top-stats-block" key={k}>
                                                            <div className="top-stats-icon top-stats-icon-cup">
                                                                {k != 0 ? <i className="rounded-no">{k + 1}</i> : <img src="/images/cup.svg" alt="" />}
                                                            </div>
                                                            <div className="top-stats-content">
                                                                <h4>{x.team_name}</h4>
                                                                <div className="d-flex gap-4">
                                                                    <p>${v.game_type == 2 || v.game_type == 3 ? parseFloat(x.all_winnings).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : parseFloat(x.winnings).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                                                    <p style={v.game_type == 2 || v.game_type == 3 || v.game_type == 4 ? hideplan : showplan}>${parseFloat(x.tie_winnings).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                                                </div>
                                                            </div>
                                                        </div>)
                                                    })}
                                                    </OwlCarousel> : <div className='text-center text-red'>Results not published yet!</div>}
                                            </div>)
                                        })}
                                    </Slide>}
                                <h4 className='quick-leaderboard-heading for-mob'>Quick Roundwise Leaderboard (<NavLink exact to={'/game/' + v.slug}>See All</NavLink>)</h4>
                            </div>)

                        }) : null}

                    </div>

                </div>}
            </div>
        </React.Fragment>
    )
}

export default TopHeaderScore
